import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { Link } from "gatsby"

import Colors from "../variables/colors"

const FourOhFour = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  margin: 0px auto;
  text-align: center;
  background-color: ${Colors.container}
`

const Title = styled.h1`
  font-size: 16vw;
  text-align: center;
  font-weight: bold;
  span {
    color: ${Colors.orange}
  }
`

const Subtitle = styled.h2`
  font-size: 2em;
`

const HomeLink = styled(Link)`
  color: ${Colors.orange};
  &:hover {
    color: ${Colors.orange_dk}
  }
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <FourOhFour>
      <Title>4<span>Oh!</span>4</Title>
      <Subtitle>Page Not Found</Subtitle>
      <p>You just hit a route that doesn&#39;t exist... Oh no!</p>
      <HomeLink to="/">Go back to home</HomeLink>
    </FourOhFour>
  </Layout>
)

export default NotFoundPage
